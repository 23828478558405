<template>
  <ValidationProvider
    ref="validatorProvider"
    :vid="id"
    :name="validatorName"
    :rules="validationRules"
    v-slot="{ valid, pristine, errors }"
    :slim="true"
  >
    <div :class="cssClasses.wrapperClasses">
      <div v-if="errors[0]" :class="cssClasses.messageClasses">{{ errors[0] }}</div>
      <div :class="cssClasses.inputHolderClasses">
        <input
          ref="input"
          v-if="withMask"
          :placeholder="placeholder"
          :name="name"
          :id="id"
          :required="required"
          :value="value"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :readonly="readonly"
          :class="cssClasses.inputClasses"
          :autofocus="autofocus"
          v-bind="$attrs"
          v-model="innerValue"
          @keydown.space="event => (nospace ? event.preventDefault() : null)"
          @click="click"
          v-mask="mask"
          :style="{
            borderColor:
              validState && pristine
                ? '#23303d'
                : validState && !valid
                ? '#e5472d'
                : valid && validState
                ? '#6dd400'
                : '',
          }"
        />
        <input
          ref="input"
          v-if="!withMask"
          :data-vv-as="placeholder"
          :name="name"
          :id="id"
          :required="required"
          :value="value"
          :type="type"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :readonly="readonly"
          :class="cssClasses.inputClasses"
          :autofocus="autofocus"
          v-bind="$attrs"
          v-model="innerValue"
          @keydown.space="event => (nospace ? event.preventDefault() : null)"
          @click="click"
          :style="{
            borderColor:
              validState && pristine
                ? '#23303d'
                : validState && !valid
                ? '#e5472d'
                : valid && validState
                ? '#6dd400'
                : '',
          }"
        />
        <span v-if="bottomBarVisible" :class="$style.bar"></span>
        <label :for="name">{{ label || placeholder }}</label>
        <slot />
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mask } from 'vue-the-mask';
export default {
  name: 'VueInput',
  inheritAttrs: false,
  components: {
    ValidationProvider,
  },
  directives: { mask },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    validatorName: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    messageOnTop: {
      type: Boolean,
      default: true,
    },
    validationRules: {
      type: [Object, String],
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    borderType: {
      type: String,
      default: 'box',
    },
    iconName: {
      type: String,
    },
    iconSize: {
      type: Object,
      default: () => ({
        width: 18,
        height: 18,
      }),
    },
    nospace: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    validState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      innerValue: '',
    };
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  computed: {
    withMask() {
      return this.mask && this.mask.length > 0;
    },
    bottomBarVisible() {
      return !(this.borderType === 'box' || this.borderType === 'left-bordered');
    },
    cssClasses() {
      const wrapperClasses = [this.$style['input-wrapper']];
      const inputHolderClasses = [this.$style['input-holder']];
      const messageClasses = [this.$style.message, this.$style.error];
      const inputClasses = [];

      if (this.disabled) {
        inputHolderClasses.push(this.$style.disabled);
        inputClasses.push(this.$style['ios-disabled-fix']);
      }

      if (this.borderType === 'box') {
        inputHolderClasses.push(this.$style['input-holder-box-border']);
      }

      if (this.borderType === 'left-bordered') {
        inputHolderClasses.push(this.$style['input-holder-left-bordered']);
      }
      if (this.value || this.placeholder) {
        inputClasses.push(this.$style.hasValue);
      }

      if (this.messageOnTop) {
        messageClasses.push(this.$style['message-top']);
        inputHolderClasses.push(this.$style['input-holder-bottom']);
      }

      if (this.type === 'text') {
        inputClasses.push(this.$style['ios-topshadow-fix']);
      }

      return { wrapperClasses, inputHolderClasses, messageClasses, inputClasses };
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  mounted() {
    if (window.IntersectionObserver) {
      this.handleObserver();
    }
  },
  beforeDestroy() {
    this.observer = null;
  },
  methods: {
    handleObserver() {
      this.observer = new IntersectionObserver(
        () => {
          if (this.autofocus && this.$refs.input) {
            this.$refs.input.focus();
          }
        },
        { root: this.$refs.input.parentElement, threshold: 1 },
      );
      this.observer.observe(this.$refs.input);
    },
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';
.input-wrapper {
  display: flex;
  flex-direction: column;
}
.input-holder {
  position: relative;
  order: 1;

  input {
    background-color: $input-background-color;
    border: none;
    border-bottom: $input-border;
    padding: $input-padding;
    display: block;
    width: 100%;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    color: $input-color;
    height: $input-height;
    border-radius: 0;
    margin: $input-margin;
    order: 1;
    outline: none !important;

    &:active,
    &:focus,
    &:hover {
      outline: none !important;
    }

    &:focus ~ label,
    &.hasValue ~ label {
      top: $input-placeholder-active-top;
      font-size: $input-placeholder-active-font-size;
      font-weight: $input-placeholder-active-font-weight;
    }

    &:focus ~ .bar:before,
    &:focus ~ .bar:after {
      width: 50%;
    }

    &[type='tel'] {
      letter-spacing: 2px;
    }
  }

  label {
    color: $input-placeholder-color;
    font-size: $input-placeholder-font-size;
    font-weight: $input-placeholder-font-weight;
    position: absolute;
    pointer-events: none;
    top: $input-placeholder-top;
    left: $input-placeholder-left;
    right: $input-placeholder-left;
    transform: translateY(-50%);
    transition: $animation-transition-duration * 2 $animation-easing all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 100%;
  }

  &.input-holder-bottom {
    order: 2;
  }

  &.input-holder-box-border {
    input {
      border: $input-border;
      border-radius: $input-border-radius;
    }
  }
  &.input-holder-left-bordered {
    border-radius: $input-border-radius;

    &:before {
      content: '';
      position: absolute;
      left: 0 top;
      left: 0;
      top: 0;
      background: linear-gradient(to top, #e02020 0%, #fa9307 100%);
      width: 8px;
      height: 100%;
      border-radius: 4p;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      /* padding-left: $input-padding-left-bordered; */
    }

    input {
      border: $input-border;
      border-left: none;
      border-radius: none;
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      margin-left: 8px;
      width: calc(100% - 8px);
      padding-left: 10px;
    }

    label {
      left: $input-placeholder-left;
    }
  }
  a {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.error {
  label {
    color: $input-error-color;
  }
  input {
    border-color: $input-error-color !important;

    &:focus ~ label,
    &.hasValue ~ label {
      color: $input-error-color;
    }
  }

  .bar {
    &:before,
    &:after {
      background: $input-error-color;
    }
  }

  .message {
    color: $input-error-color;
  }
}

.bar {
  position: relative;
  display: block;
  width: 100%;

  &:before,
  &:after {
    content: '';
    height: $input-bar-height;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $input-bar-color;
    transition: all 0.2s ease-in-out;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
}

.message {
  display: block;
  /* height: $input-message-height; */
  padding-bottom: 5px;
  padding-left: 20px;
  /* padding: $input-message-padding; */
  position: relative;
  color: $input-error-color;
  font-size: $input-message-font-size;
  font-weight: $input-message-font-weight;
  order: 2;
}

.message-top {
  order: 1;
}
.disabled {
  opacity: 0.6;
}
.ios-disabled-fix {
  //to override IOS SAFARI  0.4 opacity on disabled input
  opacity: unset;
  -webkit-text-fill-color: palette-color-level('grey', 40);
}
.ios-topshadow-fix {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
