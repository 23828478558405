<template>
  <ValidationObserver :slim="true" ref="observer" v-slot="{ passes }">
    <div :class="[...cssClasses.formWrapperClasses, formWrapperClass]">
      <form :name="name">
        <VueContentHolder :padding="bodyPadding" :class="cssClasses.formBody">
          <slot></slot>
        </VueContentHolder>
        <VueContentHolder
          :padding="footerPadding"
          :class="[...cssClasses.formFooter, footerSlotClass]"
        >
          <slot name="footer"></slot>
        </VueContentHolder>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';

export default {
  name: 'FormLoginWithCustomerNumber',
  props: {
    isInline: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    bodyPadding: {
      type: Array,
      default: () => ['0'],
    },
    footerPadding: {
      type: Array,
      default: () => ['0'],
    },
    formWrapperClass: {
      type: String,
      default: '',
      required: false,
    },
    footerSlotClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  components: {
    ValidationObserver,
    VueContentHolder,
  },
  computed: {
    cssClasses() {
      const formWrapperClasses = [this.$style['form-wrapper']];
      const formBody = [this.$style['form-body']];
      const formFooter = [this.$style['form-footer']];

      formWrapperClasses.push(
        !this.isInline ? this.$style['form-wrapper-column'] : this.$style['form-wrapper-row'],
      );

      return {
        formWrapperClasses,
        formBody,
        formFooter,
      };
    },
  },
  methods: {},
};
</script>

<style module lang="scss">
.form-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  &.form-wrapper-row {
    flex-direction: row;
  }

  &.form-wrapper-column {
    flex-direction: column;
    min-height: 100%;

    form {
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
}
</style>
