<template>
  <div class="layout-holder page-login">
    <VueAppbar
      :isPublicLayoutBar="true"
      :hasBackButton="true"
      :withBg="true"
      :isCollapsable="false"
      :isJustLogo="true"
      backButtonColor="rgb(235, 56, 50)"
    />
    <div class="layout-content">
      <slot></slot>
    </div>
    <BrandModal />
  </div>
</template>
<script>
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar.vue';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';

export default {
  name: 'LayoutAuthForm',
  components: {
    VueAppbar,
    BrandModal,
  },
};
</script>

<style scoped lang="scss">
.layout-holder {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  width: 100%;

  .layout-content {
    flex: 1;
    padding-bottom: 0;
    padding-bottom: #{$safe-area-inset-bottom};
  }
}
</style>
