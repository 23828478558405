<template>
  <LayoutAuthForm id="divFirstLoginWrapper">
    <VueForm ref="form" :bodyPadding="['0', '20']">
      <VueHeadline level="1" appearanceLevel="2">{{ headerText }}</VueHeadline>
      <VueSpacer level="20" />
      <VueInput
        name="phoneNumber"
        mask="5#########"
        id="phoneNumber"
        type="tel"
        validatorName="Telefon Numarası"
        label="Telefon Numaranız"
        placeholder="5------"
        v-model="formModel.phoneNumber"
        :messageOnTop="true"
        borderType="left-bordered"
        validationRules="required|numeric|max:10|min:10"
      />
      <div slot="footer">
        <BrandButton
          :disabled="isDisabled"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click="handleSubmit"
          >DEVAM ET</BrandButton
        >
      </div>
    </VueForm>
  </LayoutAuthForm>
</template>

<script>
import { Account } from '@/services/Api/index';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'FirstLogin',
  components: {
    LayoutAuthForm,
    VueHeadline,
    VueSpacer,
    VueForm,
    BrandButton,
    VueInput,
  },
  data() {
    return {
      isDisabled: true,
      formModel: {
        phoneNumber: '',
      },
      headerText: 'İlk Giriş / Şifremi Unuttum',
      headers: {
        1: 'İlk Giriş',
        2: 'Şifremi Unuttum',
        3: 'İlk Giriş / Şifremi Unuttum',
      },
      submitted: false,
    };
  },
  computed: {
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.header) {
      window.firstLoginHeaderCode = this.$route.query.header;
    }

    this.headerText =
      window.firstLoginHeaderCode &&
      (window.firstLoginHeaderCode == 1 || window.firstLoginHeaderCode == 2)
        ? this.headers[window.firstLoginHeaderCode]
        : this.headers[3];
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (this.formModel.phoneNumber !== '') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        const { phoneNumber } = this.formModel;

        Account.sendSmsForChangePassword(phoneNumber).then(res => {
          if (res.data.Data) {
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY }).set(
              this.formModel.phoneNumber,
            );
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
              date: new Date(),
              isRefresh: false,
            });
            this.$router.push('/auth/login/sms-verification');
          }
        });
      }
    },
  },
};
</script>
